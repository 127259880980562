export const getDay = (index, short) => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const day = short ? days[index].substring(0, 3) : days[index];

  return day;
};

export const getMonth = (index, short) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = short ? months[index].substring(0, 3) : months[index];

  return month;
};

export const formatDate = (unformattedDate, alt) => {
  let formattedDate;

  if (unformattedDate) {
    const date = new Date(unformattedDate);

    if (alt) {
      formattedDate =
        date &&
        `${getDay(
          date.getDay() === 0 ? 6 : date.getDay() - 1,
          true
        )} ${date.getDate()} ${getMonth(
          date.getMonth(),
          true
        )} ${date.getFullYear()}`;
    } else {
      formattedDate =
        date &&
        `${('0' + date.getDate()).slice(-2)}/${(
          '0' +
          (date.getMonth() + 1)
        ).slice(-2)}/${date.getFullYear()}`;
    }
  }

  return formattedDate;
};

export const stripTime = (date) => {
  if (date && date.indexOf('T') > -1) {
    return date.substring(0, date.indexOf('T'));
  }

  return date;
};
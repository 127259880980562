const React = require('react');
const Sentry = require('@sentry/browser');
const { StoreProvider } = require('./src/context/StoreContext');

const useSentry = (process.env.GATSBY_SENTRY_ENABLED == 'true');
const useSentryReplay = (process.env.GATSBY_SENTRY_REPLAY_ENABLED == 'true');
const sentryTraceSampleRate = parseFloat(process.env.GATSBY_SENTRY_TRACES_SAMPLE_RATE || 0.0);
const sentryReplaySessionSampleRate = parseFloat(process.env.GATSBY_SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0.0);
const sentryReplayErrorSampleRate = parseFloat(process.env.GATSBY_SENTRY_REPLAY_ERROR_SAMPLE_RATE || 0.0);

if (useSentry) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_SENTRY_ENVIRONMENT,
    tracesSampleRate: sentryTraceSampleRate,
    replaysSessionSampleRate: sentryReplaySessionSampleRate,
    replaysOnErrorSampleRate: sentryReplayErrorSampleRate,
    integrations: [
      ...(useSentryReplay ? [new Sentry.Replay()] : [])
    ],
    beforeSend: (event, hint) => {
      try {
        if (hint.originalException?.stack?.indexOf('www.googletagmanager.com/gtm.js?id=') > -1) {
          // MA - Ignore an errors coming from GTM-based scripts.
          return null;
        }
        if(event.exception.values[0].stacktrace.frames[0].filename === '<anonymous>') {
          // MA - Ignore any scripts with an anonymous source
          return null;
        }
      } catch (error) { }

      return event;
    }
  });

  // Add variant specific elements here to help identify the test result in error/trace logs.
  Sentry.setContext('tests', {
    checkout: 'v2'
  });
}

exports.wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

exports.shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
}) => state && state.shouldUpdateScroll;

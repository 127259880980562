import { useEffect } from 'react';

export const removeValidation = e => {
  e.target.parentElement.classList.remove('error');
  e.target.nextSibling &&
    e.target.nextElementSibling.className.includes('error') &&
    e.target.nextElementSibling.remove();
};

export const removeAllValidation = requiredFields => {
  requiredFields.forEach(({ name, type }) => {
    const input = document.querySelector(`${type}[name='${name}']`);

    if (input) {
      input.parentElement.classList.remove('error');

      input.nextSibling && input.nextElementSibling.remove();
    }
  });
};

export const useValidation = (requiredFields, dependencies) => {
  useEffect(
    () => {
      requiredFields.forEach(({ name, type }) => {
        const input = document.querySelector(`${type}[name='${name}']`);

        if (input) {
          input.addEventListener('invalid', e => {
            // add class, remove on input change
            e.target.parentElement.classList.add('error');

            if (
              !e.target.nextSibling ||
              (e.target.nextSibling &&
                !e.target.nextSibling.className.includes('error'))
            ) {
              e.target.insertAdjacentHTML(
                'afterend',
                `<span class="error">*${e.target.validationMessage}</span>`,
              );
            }
          });
        }
      });
    },
    dependencies ? dependencies : [requiredFields],
  );
};

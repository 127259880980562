import { useEffect } from 'react';
import { graphql } from 'gatsby';

export const useDataLayer = data => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (window.dataLayer && data) {
      if (data.pageData) {
        window.dataLayer.push(data.pageData);
      }

      if (data.product) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          ecommerce: {
            detail: {
              products: [data.product],
            },
          },
        });
      }

      if (data.checkout) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                step: data.checkout.step,
                option: data.checkout.option,
              },
            },
          },
        });
      }
    }
  }, [data]);
};

export const onClickDataLayer = (data, product) => {
  window.dataLayer = window.dataLayer || [];

  if (window.dataLayer && data) {
    if (data.checkout) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: data.checkout.step,
              option: data.checkout.option,
            },
            products: product,
          },
        },
      });
    } else if (data.addToCart) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          add: {
            products: product,
          },
        },
      });
    } else if (data.purchase) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: data.purchase.id,
              affiliation: data.purchase.affiliation,
              revenue: data.purchase.revenue,
              tax: data.purchase.tax,
              shipping: data.purchase.shipping,
            },
            products: product,
          },
        },
      });
    } else if (data.customData) {
      window.dataLayer.push({ ...data.customData });
    }
  }
};

export const DataLayerFragment = graphql`
  fragment DataLayerFragment on SPASEEKERS_GTMDataLayer {
    pageData {
      carParking
      country
      county
      disabledAccess
      guestRating
      hotelRating
      package
      packageCategory
      packageType
      pageType
      region
      spaGroup
      spaName
      town
      wifi
    }
    product {
      brand
      category
      id
      list
      name
      price
    }
  }
`;

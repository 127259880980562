import { useState, useEffect } from 'react';

const useLocationId = () => {
  const [locationId, setLocationId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ipAddress = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => data.ip);

        const locationId = await fetch(
          `${process.env.GATSBY_SPASEEKERS_API_URL}/locations/nearest-region?address=${ipAddress}`,
        )
          .then(response => response.json())
          .then(({ data }) => data.locationId);

        setLocationId(locationId);
      } catch {}
      // MA - Swallow this exception, we can't depend on ipify.com all the time, it breaks.
    };

    fetchData();
  }, []);

  return locationId;
};

export const useLocationContent = endpoint => {
  const [locationContent, setLocationContent] = useState();

  const locationId = useLocationId();

  useEffect(() => {
    const fetchData = async () => {
      if (locationId) {
        const data = await fetch(
          `${process.env.GATSBY_SPASEEKERS_API_URL}/${endpoint}?locationId=${locationId}`,
        )
          .then(response => response.json())
          .then(({ data }) => data);

        setLocationContent(data);
      }
    };

    fetchData();
  }, [locationId, endpoint]);

  return locationContent;
};

export const useLocations = (term, searchType) => {
  const [locations, setLocations] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `${process.env.GATSBY_SPASEEKERS_API_URL}/locations?term=${
          term || ''
        }&searchType=${searchType || ''}`,
      )
        .then(response => response.json())
        .then(({ data }) => data);

      setLocations(data);
    };

    if (!!term) {
      fetchData();
    }
  }, [term, searchType]);

  return locations;
};

export const getLocation = async (latitude, longitude, searchType) => {
  const locationName = await fetch(
    `${process.env.GATSBY_SPASEEKERS_API_URL}/locations/nearest-county?latitude=${latitude}&longitude=${longitude}`,
  )
    .then(response => response.json())
    .then(({ data }) => data.name);

  const locations = await fetch(
    `${
      process.env.GATSBY_SPASEEKERS_API_URL
    }/locations?term=${locationName}&searchType=${searchType || ''}`,
  )
    .then(response => response.json())
    .then(({ data }) => data.locations);

  return locations[0];
};

export const useResults = (
  initialResults,
  options,
  locationData,
  arePackages,
  view,
) => {
  const [isClientSide, setIsClientSide] = useState(
    locationData.search || options.arrivalDate,
  );

  const [results, setResults] = useState(initialResults);

  const camelize = text => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    text = text.substring(0, 1).toLowerCase() + text.substr(1);

    return text;
  };

  const transformMetadata = meta => {
    if (meta?.metadata) {
      const mapped = [];

      for (let i = 0; i < meta.metadata.length; i++) {
        const row = meta.metadata[i];
        const group = camelize(row.group);
        let key = { length: 0 };
        if (!mapped[group]) {
          mapped[group] = key;
        } else {
          key = mapped[group];
        }
        key[row.value] = row.filtered;
        key.length++;
      }

      meta.metadata = mapped;
    }

    return meta;
  };

  useEffect(() => {
    if (isClientSide) {
      const urlParams = new URLSearchParams(locationData.search);
      const pageParam = parseInt(urlParams.get('page')) || 1;
      options.page = pageParam;
      options.pageSize = view === 'map' ? 50 : 10;

      if (locationData.state) {
        if (locationData.state.searchType) {
          options.searchType = locationData.state.searchType;
        }

        if (locationData.state.locationId) {
          options.locationId = locationData.state.locationId;
        }

        if (locationData.state.arrivalDate) {
          options.arrivalDate = locationData.state.arrivalDate;
        }
      }

      const fetchData = async () => {
        const data = await fetch(
          `${process.env.GATSBY_SPASEEKERS_API_URL}/${
            arePackages ? 'packages' : 'spas'
          }`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(options),
          },
        )
          .then(response => response.json())
          .then(({ data, meta }) => ({
            data: data,
            meta: transformMetadata(meta),
            isClientSide,
          }));

        setResults(data);
      };

      fetchData();
    }

    setIsClientSide(true);
  }, [
    options,
    locationData.search,
    locationData.state && locationData.state.searchType,
    locationData.state && locationData.state.locationId,
    locationData.state && locationData.state.arrivalDate,
    arePackages,
    view,
  ]);

  return isClientSide
    ? results
    : {
        data: results.data,
        meta: transformMetadata(results.meta),
      };
};

export const useCalendar = ({
  start,
  months,
  spaId,
  packageId,
  guests,
  rooms,
  locationId,
  searchType,
}) => {
  const [calendar, setCalendar] = useState();

  const formatStart = start => {
    const formattedStart = `${start.getFullYear()}-${(
      '0' +
      (start.getMonth() + 1)
    ).slice(-2)}`;

    return formattedStart;
  };

  useEffect(() => {
    const isSearch = !spaId;

    const fetchData = async () => {
      setCalendar();

      let params = [`start=${formatStart(start)}`, `months=${months}`];
      if (isSearch) {
        searchType && params.push(`searchType=${searchType}`);
        locationId && params.push(`locationId=${locationId}`);
      } else {
        spaId && params.push(`spaId=${spaId}`);
        packageId && params.push(`packageId=${packageId}`);
        guests && params.push(`guests=${guests}`);
        rooms && params.push(`rooms=${rooms}`);
      }

      const data = await fetch(
        `${process.env.GATSBY_SPASEEKERS_API_URL}/calendar${
          isSearch ? '/general' : ''
        }?${params.join('&')}`,
        {
          credentials: 'include',
        },
      )
        .then(response => response.json())
        .then(({ data }) => data);

      setCalendar(data);
    };

    if (isSearch || (spaId && packageId)) {
      fetchData();
    }
  }, [start, months, spaId, packageId, guests, rooms]);

  return calendar;
};

export const getLocationHighRatingSpas = async locationID => {
  const locationHighRatingSpas = await fetch(
    `${process.env.GATSBY_SPASEEKERS_API_URL}/spas`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locationID: locationID,
        sortProfile: 'RatingHigh',
        page: 1,
        pageSize: 3,
      }),
    },
  )
    .then(response => response.json())
    .then(({ data }) => data);

  return locationHighRatingSpas;
};

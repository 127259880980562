import { navigate } from 'gatsby';

const sendRequest = (requestOptions, endpoint) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.GATSBY_SPASEEKERS_API_URL}/${endpoint}`,
      requestOptions,
    )
      .then(response => {
        resolve(response);
      })
      .catch(e => reject(e));
  });
};

export const submitForm = async (e, formName, endpoint, setSubmitted) => {
  e.preventDefault();

  const form = document.getElementById(formName);
  const formData = new FormData(form);

  let data = {};

  for (var pair of formData.entries()) {
    // Re-formatting data from form to match API
    if (pair[0] === 'break' || pair[0] === 'verify') {
      pair[1] = pair[1] === 'true' ? true : false;
    } else if (
      pair[0] === 'numberOfGuests' ||
      pair[0] === 'spaId' ||
      pair[0] === 'packageId' ||
      pair[0] === 'serviceRating' ||
      pair[0] === 'conditionRating' ||
      pair[0] === 'overallRating'
    ) {
      pair[1] = parseInt(pair[1]);
    } else if (pair[0] === 'newsletter-optin') {
      pair[0] = 'optIn';
      pair[1] = true;
    } else if (pair[0] === 'flexibleOnDates') {
      pair[0] = 'flexibleOnDates';
      pair[1] = true;
    } else if (pair[0] === 'dateOfVisit') {
      pair[1] = `${pair[1]}-01`;
    } else if (pair[0] === 'alternativeDate' && pair[1] === '') {
      // If alternative date is empty
      continue;
    } else if (pair[0] === 'minimumAge' && pair[1] === '') {
      // If minimum age is empty
      continue;
    }
    data[pair[0]] = pair[1];
  }

  // Send directly to API
  var requestHeaders = new Headers();

  requestHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: requestHeaders,
    body: Object.keys(data).length > 0 ? JSON.stringify(data) : undefined,
  };

  await sendRequest(requestOptions, endpoint);

  if (setSubmitted) {
    setSubmitted(true);
  } else {
    navigate('/newsletter-confirmation/');
  }
};

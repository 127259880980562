import { useState, useEffect } from 'react';

export const useTestVariation = localStorageKey => {
  const [testVariation, setTestVariation] = useState();

  const checkTestVariation = () => {
    setTestVariation(localStorage.getItem(localStorageKey));
  };

  useEffect(() => {
    checkTestVariation();

    window.addEventListener('storage', checkTestVariation);

    return () => {
      window.removeEventListener('storage', checkTestVariation);
    };
  }, [localStorageKey]);

  return testVariation;
};
